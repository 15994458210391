// React
import React, { useState, useEffect } from "react";
// Language
import strings from "../../language/language";
// Redux
import { useSelector, useDispatch } from "react-redux";
// MUI
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Badge,
} from "@mui/material";
// React Icons
import { AiOutlineUser } from "react-icons/ai";
import { FaTicketAlt, FaQuestionCircle } from "react-icons/fa";
// Router
import { useHistory } from "react-router-dom";

const NavBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [haveTicket, setHaveTicket] = useState(false);
  const [invisible, setInvisible] = useState(false);
  const userData = useSelector((state) => state.login);
  const ticketData = useSelector((state) => state.ticket);

  useEffect(() => {
    if (ticketData?.userTicket && userData.Role === "USER") {
      if (ticketData?.userTicket.length) {
        ticketData?.userTicket.forEach((ticket) => {
          if (ticket.Status !== "CLOSED") {
            setHaveTicket(true);
          }
        });
      } else {
        if (ticketData.userTicket.Status !== "CLOSED") {
          setHaveTicket(true);
        }
      }
    }
    if (haveTicket) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [ticketData, haveTicket]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const HandleHome = () => {
    history.push("/list");
  };

  const HandleFAQ = () => {
    history.push("/faqs");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: "#1F3C73" }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={HandleHome}
          >
            <Badge
              color="secondary"
              invisible={invisible}
              badgeContent="1"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <FaTicketAlt />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={HandleFAQ}
          >
            <FaQuestionCircle />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Segex
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
            sx={{ mr: 2 }}
          >
            <AiOutlineUser />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>{userData.UserName}</MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                localStorage.removeItem("token");
                dispatch({ type: "LOGOUT" });
                history.push("/login");
              }}
            >
              {strings.btn.logout}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
