// React
import React from "react";
// Styled
import styled from "styled-components";
// MUI
import { Paper, Typography } from "@mui/material";

import { BsCircleFill } from "react-icons/bs";

const TicketUnit = ({ title, subtitle, date, active, HandleClick, id }) => {
  const [subtit, setSubTit] = React.useState("");
  const HandleState = (state) => {
    switch (state) {
      case 0:
        return (
          <BsCircleFill
            style={{
              width: "50%",
              height: "auto",
              maxWidth: "20px",
              minWidth: "15px",
            }}
            color="#eb2f00"
          />
        );
      case 1:
        return (
          <BsCircleFill
            style={{
              width: "50%",
              height: "auto",
              maxWidth: "20px",
              minWidth: "15px",
            }}
            color="#78ff17"
          />
        );
      case 2:
        return (
          <BsCircleFill
            style={{
              width: "50%",
              height: "auto",
              maxWidth: "20px",
              minWidth: "15px",
            }}
            color="#ffda07"
          />
        );
      default:
        return "";
    }
  };

  React.useEffect(() => {
    if (subtitle) {
      let subText = subtitle;
      if (subText.length > 40) {
        subText = subText.substring(0, 40);
        subText += " ...";
        setSubTit(subText);
      }
    }
  }, [subtitle]);

  return (
    <StyDiv
      elevation={2}
      onClick={() => {
        HandleClick(id);
      }}
    >
      <StyState>{HandleState(active)}</StyState>
      <StyTextCont>
        <Typography>{title}</Typography>
        <Typography variant="subtitle2">
          {subtit ? subtit : subtitle}
        </Typography>
        <Typography variant="caption">{date}</Typography>
      </StyTextCont>
    </StyDiv>
  );
};

const StyDiv = styled(Paper)`
  display: flex;
  align-items: center;
  padding: 0.8rem 0.5rem;
  width: 100%;
  height: 90px;
  max-width: 600px;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const StyState = styled.div`
  height: 100%;
  width: 5%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyTextCont = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
`;

export default TicketUnit;
