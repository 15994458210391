import React, { useEffect } from "react";
// Styled
import styled from "styled-components";
// Language
import strings from "../../language/language";
// Redux
import { useDispatch } from "react-redux";
// Router
import { useHistory } from "react-router-dom";
// Axios
import axios from "axios";
import { Account, Ticket } from "../../Api";
// Spring
import { useSpring, animated } from "react-spring";
// MUI
import { LinearProgress, Typography } from "@mui/material";
// JWT
import jwt_decode from "jwt-decode";

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      let savedToken = jwt_decode(localStorage.getItem("token"));
      console.log(savedToken);
      if (new Date(savedToken.exp) < Date.now()) {
        console.log("token expirada");
        //HandleLogin(savedToken.UserName, savedToken.Password);
      }
      dispatch({ type: "LOGIN", payload: savedToken });
      const newToken = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${newToken}` },
      };
      axios
        .get(Account, config)
        .then((resp) => {
          console.log(resp);
          const userData = {
            Role: resp.data.Role,
            UserName: resp.data.UserName,
            _id: resp.data._id,
            isActive: resp.data.isActive,
          };
          dispatch({ type: "START_UPDATE", payload: userData });
          history.push("/list");
        })
        .catch((err) => {
          localStorage.removeItem("token");
          history.push("/login");
        });
      dispatch({ type: "TOKEN", Token: newToken });
    } else {
      history.push("/login");
    }
  }, [dispatch, history]);

  return (
    <StyDiv>
      <Typography style={{ marginBottom: "2rem" }} variant="h3">
        {strings.ttls.lding}
      </Typography>
      <LinearProgress
        style={{ width: "100%", height: "1rem" }}
        variant="indeterminate"
        color="primary"
      />
    </StyDiv>
  );
};

const StyDiv = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Home;
