// React
import React, { useState, useEffect } from "react";
import getVideoId from "get-video-id";
// MUI
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionCust = ({ question, answer, video }) => {
  const [urlVideo, setUrlVideo] = useState(null);

  useEffect(() => {
    if (video) {
      const newUrl = getVideoId(video);
      setUrlVideo(newUrl.id);
    }
  }, [setUrlVideo, video]);

  return (
    <Accordion>
      <AccordionSummary
        style={{ backgroundColor: "#1F3C73", color: "#fff" }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ backgroundColor: "#6599CC" }}>
        {answer && <Typography>{answer}</Typography>}
        {urlVideo && (
          <iframe
            width="100%"
            height="400"
            src={`https://www.youtube.com/embed/${urlVideo}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCust;
