// React
import React, { useEffect, useState } from "react";
// Styled
import styled from "styled-components";
// Language
import strings from "../language/language";
// Redux
import { useSelector, useDispatch } from "react-redux";
// Router
import { useHistory } from "react-router-dom";
// Axios
import axios from "axios";
import { Ticket } from "../Api";
import NavBar from "../components/home/NavBar";
// Spring
import { useSpring, animated } from "react-spring";
// MUI
import { DataGrid } from "@mui/x-data-grid";
import TicketUnit from "../components/dashboard/TicketUnit";

const TicketList = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login);
  const ticketData = useSelector((state) => state.ticket);
  const history = useHistory();
  const [rows, setRows] = useState(null);

  const HandleRows = (tickets) => {
    let newRow = [];
    tickets.forEach((ticket) => {
      let newTicket = {
        id: ticket._id,
        Author: ticket.Author.UserName,
        Status: HandlerStateText(ticket.Status),
        Title: ticket.Title,
        Content: ticket.Content,
        Created: `${new Date(ticket.Created).getDate()}/${
          new Date(ticket.Created).getMonth() + 1
        }/${new Date(ticket.Created).getFullYear()}`,
      };
      newRow.push(newTicket);
    });
    setRows(newRow);
  };

  const HandlerStateText = (value) => {
    switch (value) {
      case "CLOSED":
        return 0;
      case "OPEN":
        return 1;
      case "PENDING":
        return 2;
      default:
        return "not found";
    }
  };

  const HandleSelected = (value) => {
    history.push(`/ticket/${value}`);
  };

  const HandleTickets = async (Role, Token) => {
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    switch (Role) {
      case "USER":
        axios
          .get(Ticket, config)
          .then((res) => {
            HandleRows(res.data.data);
            dispatch({ type: "USER_TICKET", userTicket: res.data.data });
            dispatch({ type: "ADMIN_TICKETS", adminTickets: null });
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "ADMIN":
      case "SUPPORT":
        axios
          .get(`${Ticket}/list`, config)
          .then((res) => {
            HandleRows(res.data.data);
            dispatch({ type: "ADMIN_TICKETS", adminTickets: res.data.data });
            dispatch({ type: "USER_TICKET", userTicket: null });
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    HandleTickets(userData.Role, userData.Token);
  }, []);

  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  return (
    <StyDiv>
      <NavBar />
      <TicketContainer style={props}>
        {rows &&
          rows.map((tkt) => {
            return (
              <TicketUnit
                title={tkt.Title}
                subtitle={tkt.Content}
                active={tkt.Status}
                date={tkt.Created}
                id={tkt.id}
                HandleClick={HandleSelected}
              />
            );
          })}
      </TicketContainer>
    </StyDiv>
  );
};

const StyDiv = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const TicketContainer = styled(animated.div)`
  width: 100%;
  height: 90%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default TicketList;
