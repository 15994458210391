// React
import React, { useEffect } from "react";
// Styled
import styled from "styled-components";
// Language
import strings from "../language/language";
// Redux
import { useDispatch } from "react-redux";
// Router
import { useHistory } from "react-router-dom";
// Axios
import axios from "axios";
import { Account, Ticket } from "../Api";
// Spring
import { useSpring, animated } from "react-spring";
// MUI
import { LinearProgress, Typography } from "@mui/material";
// JWT
import jwt_decode from "jwt-decode";

const LoginCheck = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const url = history.location.pathname;

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  const HandleTickets = async (Role, Token) => {
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    switch (Role) {
      case "USER":
        axios
          .get(Ticket, config)
          .then((res) => {
            dispatch({ type: "USER_TICKET", userTicket: res.data.data });
            dispatch({ type: "ADMIN_TICKETS", adminTickets: null });
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "ADMIN":
      case "SUPPORT":
        axios
          .get(`${Ticket}/list`, config)
          .then((res) => {
            dispatch({ type: "ADMIN_TICKETS", adminTickets: res.data.data });
            dispatch({ type: "USER_TICKET", adminTickets: null });
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      default:
        return;
    }
  };

  const HandleLogin = async (UserName, Password) => {
    axios
      .post(Account, {
        UserName,
        Password,
      })
      .then((res) => {
        dispatch({
          type: "SET_MSG",
          message: strings.forms.msg.loginok,
          severity: "success",
        });
        dispatch({ type: "SWITCH_ON" });
        const newToken = jwt_decode(res.data.token);
        dispatch({ type: "LOGIN", payload: newToken });
        dispatch({ type: "TOKEN", Token: res.data.token });
        localStorage.setItem("token", res.data.token);
        HandleTickets(newToken.Role, res.data.token);
        if (newToken.Role === "USER") {
          history.push("/faqs");
        } else if (newToken.Role === "ADMIN" || newToken.Role === "SUPPORT") {
          history.push("/list");
        }
      })
      .catch((err) => {
        return;
      });
  };

  useEffect(() => {
    let newToken;
    if (localStorage.getItem("token")) {
      newToken = jwt_decode(localStorage.getItem("token"));
      HandleTickets(newToken.Role, localStorage.getItem("token"));
      if (newToken.Role === "USER") {
        history.push("/faqs");
        return;
      } else if (newToken.Role === "ADMIN" || newToken.Role === "SUPPORT") {
        history.push("/list");
        return;
      }
    }

    let buff = new Buffer.from(url.slice(17), "base64");
    let decodedData = buff.toString("utf-8");
    let newData = JSON.parse(decodedData);
    let appkey = newData.appKey;
    delete newData.appKey;
    const config = {
      headers: { appkey },
    };
    axios
      .put(Account, newData, config)
      .then((resp) => {
        HandleLogin(newData.UserName, newData.Password);
      })
      .catch((err) => {
        if (err.response.data.code === 11000) {
          HandleLogin(newData.UserName, newData.Password);
        }
      });
  }, []);

  return (
    <StyDiv style={props}>
      <Typography style={{ marginBottom: "2rem" }} variant="h3">
        {strings.ttls.lding}
      </Typography>
      <LinearProgress
        style={{ width: "100%", height: "1rem" }}
        variant="indeterminate"
        color="primary"
      />
    </StyDiv>
  );
};

const StyDiv = styled(animated.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default LoginCheck;
