import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  es: {
    ttls: {
      faqs: "Preguntas frecuentes",
      lding: "Cargando",
    },
    btn: {
      load: "Cargando..",
      login: "Ingresar",
      logout: "Cerrar sesión",
      opntkt: "Abra un ticket aquí",
      create: "Crear",
      seetkt: "Ver ticket actual",
      send: "Enviar",
      closetkt: "Cerrar ticket",
    },
    forms: {
      msg: {
        wlcm: "Bienvenido a Segex",
        loginok: "Correcto",
        login: "Iniciar sesión",
        createtkt: "Crear Ticket",
      },
      inputs: {
        user: "Usuario",
        pass: "Contraseña",
        ttle: "Título",
        cntnt: "Describe el problema..",
        msg: "Mensaje..",
      },
      error: {
        min: "Requiere minimo 4 caracteres",
        min10: "Requiere minimo 10 caracteres",
        req: "Campo requerido",
        usrnotf: "Usuario no encontrado",
        wrngpass: "Contraseña inválida",
        alrdytkt: "Ya tenés un ticket activo",
      },
    },
    tkt: {
      ttl: "Titulo",
      usr: "Usuario",
      sprt: "Soporte",
      iss: "Problema",
      date: "Fecha",
      ste: "Estado",
      clsed: "Cerrado",
      open: "Abierto",
      pdng: "Pendiente",
      blddvce: "Build Device",
      mdldvce: "Model Device",
      mdlprd: "Model Product",
      appvrs: "App Version",
    },
  },
});

export default strings;
