const initState = {
  open: false,
  message: "",
  severity: "success",
};

const snackDataReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_MSG":
      return {
        ...state,
        message: action.message,
        severity: action.severity,
      };
    case "SWITCH_ON":
      return {
        ...state,
        open: true,
      };
    case "SWITCH_OFF":
      return {
        ...state,
        open: false,
      };
    default:
      return { ...state };
  }
};

export default snackDataReducer;
