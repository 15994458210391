// React
import React, { useEffect } from "react";
// Styled
import styled from "styled-components";
// Router
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
// MUI
import { Snackbar, Alert } from "@mui/material";
// Redux
import { useSelector, useDispatch } from "react-redux";
// Axios
import axios from "axios";
import { Account } from "./Api";
// Pages
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Faqs from "./pages/Faqs";
import LoginCheck from "./pages/LoginCheck";
import CreateTicket from "./pages/CreateTicket";
import NewTicketList from "./pages/NewTicketList";
// JWT
import jwt_decode from "jwt-decode";
// Language
import strings from "./language/language";
import Ticket from "./pages/Ticket";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const snackData = useSelector((state) => state.snackData);

  const HandleLogin = async (UserName, Password) => {
    axios
      .post(Account, {
        UserName,
        Password,
      })
      .then((res) => {
        dispatch({
          type: "SET_MSG",
          message: strings.forms.msg.loginok,
          severity: "success",
        });
        dispatch({ type: "SWITCH_ON" });
        const newToken = jwt_decode(res.data.token);
        dispatch({ type: "LOGIN", payload: newToken });
        dispatch({ type: "TOKEN", Token: res.data.token });
        localStorage.setItem("token", res.data.token);
        if (newToken.Role === "USER") {
          history.push("/faqs");
        } else if (newToken.Role === "ADMIN" || newToken.Role === "SUPPORT") {
          history.push("/admin");
        }
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      let savedToken = jwt_decode(localStorage.getItem("token"));
      console.log(savedToken);
      //if (new Date(savedToken.exp) < Date.now()) {
      //console.log("token expirada");
      //HandleLogin(savedToken.UserName, savedToken.Password);
      //}
      dispatch({ type: "LOGIN", payload: savedToken });
      const newToken = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${newToken}` },
      };
      axios
        .get(Account, config)
        .then((resp) => {
          const userData = {
            Role: resp.data.Role,
            UserName: resp.data.UserName,
            _id: resp.data._id,
            isActive: resp.data.isActive,
          };
          dispatch({ type: "START_UPDATE", payload: userData });
        })
        .catch((err) => {
          localStorage.removeItem("token");
          history.push("/login");
        });
      dispatch({ type: "TOKEN", Token: newToken });
    }
  }, [dispatch, history]);

  return (
    <StyDiv>
      <Switch location={location} key={location.pathname}>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/list">
          <NewTicketList />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/faqs">
          <Faqs />
        </Route>
        <Route path="/processapplogin/:id" exact>
          <LoginCheck />
        </Route>
        <Route path="/createticket">
          <CreateTicket />
        </Route>
        <Route path="/ticket/:id" exact>
          <Ticket />
        </Route>
      </Switch>
      <Snackbar
        open={snackData.open}
        autoHideDuration={6000}
        onClose={() => dispatch({ type: "SWITCH_OFF" })}
      >
        <Alert
          onClose={() => dispatch({ type: "SWITCH_OFF" })}
          severity={snackData.severity}
        >
          {snackData.message}
        </Alert>
      </Snackbar>
    </StyDiv>
  );
}

const StyDiv = styled.div`
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
`;

export default App;
