// React
import React, { useState, useEffect } from "react";
// Styled
import styled from "styled-components";
// Language
import strings from "../language/language";
import { Typography, Button } from "@mui/material";
import AccordionCust from "../components/faqs/AccordionCust";
// Image
import Logo from "../assets/Logo.png";
import NavBar from "../components/home/NavBar";
// Spring
import { useSpring, animated } from "react-spring";
// Redux
import { useSelector } from "react-redux";
// Router
import { useHistory } from "react-router-dom";

const Faqs = () => {
  const ticketData = useSelector((state) => state.ticket);
  const [haveTicket, setHaveTicket] = useState(false);
  const history = useHistory();
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  const roleData = useSelector((state) => state.login.Role);

  const HandleCreateTicket = () => {
    if (haveTicket) {
      history.push(
        `/ticket/${ticketData.userTicket[ticketData.userTicket.length - 1]._id}`
      );
    } else {
      history.push("/createticket");
    }
  };

  useEffect(() => {
    if (ticketData?.userTicket) {
      if (!ticketData.userTicket.length) {
        if (ticketData.userTicket.Status !== "CLOSED") {
          setHaveTicket(true);
        }
      } else {
        ticketData.userTicket.forEach((ticket) => {
          if (ticket.Status !== "CLOSED") {
            setHaveTicket(true);
          }
        });
      }
    }
  }, []);

  return (
    <StyDiv>
      <NavBar />
      <AnimDiv style={props}>
        <ImgTypoCont>
          <LogoImg src={Logo} alt="Logo" />
          <Typography marginBottom="1rem" variant="h5">
            {strings.ttls.faqs}
          </Typography>
        </ImgTypoCont>
        <BtnAccorCont>
          <AccordionContainer>
            <AccordionCust
              question="No puedo conectarme a internet, ¿Que debo intentar?"
              answer="Prueba reiniciando el dispositivo."
              video="https://www.youtube.com/watch?v=aYV0ZPvPaCM"
            />
            <AccordionCust
              question="No puedo iniciar una aplicación."
              answer="Intenta reiniciando el dispositivo."
            />
            <AccordionCust
              question="Mi pantalla no responde"
              answer="Te adjuntamos un video de como resolverlo"
              video="https://www.youtube.com/watch?v=rkolgO0wRWc"
            />
            <AccordionCust
              question="El audio de mi dispositivo se escucha bajo"
              answer="Prueba aumentando el volumen desde los ajustes"
            />
            <AccordionCust
              question="Mi dispositivo no abre aplicaciones"
              answer="Prueba reiniciando el dispositivo"
            />
            <AccordionCust
              question="No encuentro mi casilla de correo"
              answer="Busca la aplicación llamada Correo en la lista de aplicaciones"
            />
            <AccordionCust
              question="Mi dispositivo no enciende"
              answer="Revisa que tenga carga"
            />
          </AccordionContainer>
          {roleData === "USER" && (
            <Button
              onClick={HandleCreateTicket}
              style={{
                textTransform: "none",
                width: "100%",
                maxWidth: "700px",
                margin: "1rem",
              }}
              variant="contained"
              color="success"
            >
              {haveTicket ? strings.btn.seetkt : strings.btn.opntkt}
            </Button>
          )}
        </BtnAccorCont>
      </AnimDiv>
    </StyDiv>
  );
};

const StyDiv = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-x: hidden;
`;

const AccordionContainer = styled.div`
  width: 100%;
  max-width: 700px;
  height: 100%;
  overflow-y: auto;
`;

const LogoImg = styled.img`
  width: 18%;
  min-width: 200px;
  max-width: 350px;
  height: auto;
  margin-bottom: 1rem;
`;

const ImgTypoCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const BtnAccorCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70%;
`;

const AnimDiv = styled(animated.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1rem;
`;

export default Faqs;
