// React
import React, { useEffect, useState } from "react";
// Styled
import styled from "styled-components";
// Language
import strings from "../language/language";
// Redux
import { useSelector } from "react-redux";
// Router
import { useHistory } from "react-router-dom";
// Axios
import axios from "axios";
import { Ticket as TKTAPI } from "../Api";
import NavBar from "../components/home/NavBar";
// Spring
import { useSpring, animated } from "react-spring";
// MUI
import { Divider, Paper, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
// Formik & Yup
import { useFormik } from "formik";
import * as Yup from "yup";

const Ticket = () => {
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [refreshTkt, setRefreshTkt] = useState(false);
  const history = useHistory();
  const url = history.location.pathname;
  const Token = useSelector((state) => state.login.Token);
  const roleData = useSelector((state) => state.login.Role);
  const userId = useSelector((state) => state.login._id);
  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    axios
      .get(`${TKTAPI}/${url.slice(8)}`, config)
      .then((res) => {
        setTicket(res.data);
        console.log(res.data);
        if (res.data.Status === "CLOSED") {
          setDisabled(true);
        }
        if (
          userId ===
            res.data.Comments[res.data.Comments.length - 1]?.Author._id &&
          roleData === "USER"
        ) {
          setDisabled(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [refreshTkt, Token, url]);

  const sendMsgSchema = Yup.object().shape({
    Content: Yup.string()
      .min(10, strings.forms.error.min10)
      .required(strings.forms.error.req),
  });

  const HandleSendMessage = (values) => {
    HandleLoading();
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    const data = {
      _id: url.slice(8),
      Content: values.Content,
    };
    axios
      .post(TKTAPI, data, config)
      .then((res) => {
        HandleLoading();
        setRefreshTkt((prevValue) => !prevValue);
        formik.resetForm();
      })
      .catch((err) => {
        HandleLoading();
        console.log(err.response.data);
      });
    console.log(ticket);
  };

  const formik = useFormik({
    initialValues: {
      Content: "",
    },
    validationSchema: sendMsgSchema,
    onSubmit: HandleSendMessage,
  });

  const HandlerStateText = (value) => {
    switch (value) {
      case "CLOSED":
        return strings.tkt.clsed;
      case "OPEN":
        return strings.tkt.open;
      case "PENDING":
        return strings.tkt.pdng;
      default:
        return "Not found";
    }
  };

  const HandleLoading = () => {
    setLoading((prevValue) => !prevValue);
  };

  const HandleCloseTicket = () => {
    HandleLoading();
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    axios
      .delete(`${TKTAPI}/${ticket._id}`, config)
      .then((res) => {
        setRefreshTkt((prevValue) => !prevValue);
        HandleLoading();
      })
      .catch((err) => {
        console.log(err.response.data);
        HandleLoading();
      });
  };

  return (
    <StyDiv>
      <NavBar />
      <TicketCont style={props}>
        <TitleCont>
          {ticket && (
            <Typography variant="h5" component="span">
              {ticket.Title}
            </Typography>
          )}
          {ticket && (
            <Typography variant="h5" component="span">
              {HandlerStateText(ticket.Status)}
            </Typography>
          )}
        </TitleCont>
        <Paper
          style={{
            width: "100%",
            height: "auto",
            padding: "2rem",
            overflowY: "auto",
            alignSelf: "flex-start",
          }}
          elevation={5}
        >
          {ticket && (
            <Typography variant="h5">{ticket.Author.UserName}</Typography>
          )}
          <Divider sx={{ margin: "1rem 0" }} />
          {ticket && <Typography variant="h6">{ticket.Content}</Typography>}
          <Divider sx={{ margin: "1rem 0" }} />
          {ticket && (
            <Typography variant="h7">
              {`${strings.tkt.blddvce}: ${ticket.Author.Metadata.buildDevice}`}{" "}
              <br />
              {`${strings.tkt.mdldvce}: ${ticket.Author.Metadata.modelDevice}`}{" "}
              <br />
              {`${strings.tkt.mdlprd}: ${ticket.Author.Metadata.modelProduct}`}{" "}
              <br />
              {`${strings.tkt.appvrs}: ${ticket.Author.Metadata.appVersion}`}{" "}
              <br />
            </Typography>
          )}
        </Paper>
        <BubbleCont>
          {ticket?.Comments &&
            ticket.Comments.map((Comment) => {
              return (
                <Paper
                  style={{
                    width: "100%",
                    height: "auto",
                    padding: "2rem",
                    marginBottom: "0.5rem",
                  }}
                  elevation={2}
                >
                  <Typography variant="h5">
                    {Comment.Author.UserName}
                  </Typography>
                  <Divider sx={{ margin: "0.5rem 0" }} />
                  <Typography variant="h6">{Comment.Content}</Typography>
                </Paper>
              );
            })}
        </BubbleCont>
        <Paper
          style={{
            width: "100%",
            height: "auto",
            padding: "1rem",
          }}
          elevation={5}
        >
          <TextField
            disabled={disabled}
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            label={strings.forms.inputs.msg}
            name="Content"
            value={formik.values.Content}
            onChange={formik.handleChange}
            error={formik.touched.Content && Boolean(formik.errors.Content)}
            helperText={formik.touched.Content && formik.errors.Content}
          />
          <ButtonCont>
            <LoadingButton
              style={{
                textTransform: "none",
                width: "20%",
                minWidth: "100px",
                marginLeft: "1rem",
              }}
              disabled={disabled}
              margin="dense"
              onClick={formik.handleSubmit}
              loading={loading}
              loadingIndicator={strings.btn.load}
              fullWidth
              color="success"
              variant="contained"
            >
              {strings.btn.send}
            </LoadingButton>
            {ticket?.Status !== "CLOSED" && roleData !== "USER" && (
              <LoadingButton
                style={{
                  textTransform: "none",
                  width: "20%",
                  minWidth: "100px",
                }}
                disabled={disabled}
                margin="dense"
                onClick={HandleCloseTicket}
                loading={loading}
                loadingIndicator={strings.btn.load}
                fullWidth
                color="error"
                variant="contained"
              >
                {strings.btn.closetkt}
              </LoadingButton>
            )}
          </ButtonCont>
        </Paper>
      </TicketCont>
    </StyDiv>
  );
};

const StyDiv = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const TicketCont = styled(animated.div)`
  min-height: 90%;
  height: auto;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-self: center;
`;

const BubbleCont = styled.div`
  width: 100%;
  min-height: 40%;
  margin-top: 1rem;
`;

const ButtonCont = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: auto;
  margin-top: 1rem;
`;

const TitleCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 2rem 0 1rem 0;
  padding: 0 1rem;
`;

export default Ticket;
