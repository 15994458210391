import { combineReducers } from "redux";

// Reducers
import loginReducer from "./loginReducer";
import snackDataReducer from "./snackDataReducer";
import ticketReducer from "./ticketReducer";

const allReducers = combineReducers({
  login: loginReducer,
  snackData: snackDataReducer,
  ticket: ticketReducer,
});

export default allReducers;
