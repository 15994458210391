// React
import React, { useState } from "react";
// Styled
import styled from "styled-components";
// Language
import strings from "../language/language";
// Redux
import { useSelector, useDispatch } from "react-redux";
import NavBar from "../components/home/NavBar";
// Axios
import axios from "axios";
import { Ticket } from "../Api";
// Formik & Yup
import { useFormik } from "formik";
import * as Yup from "yup";
// MUI
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
// Image
import Logo from "../assets/Logo.png";
// Router
import { useHistory } from "react-router-dom";
// Spring
import { useSpring, animated } from "react-spring";

const CreateTicket = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const userToken = useSelector((state) => state.login.Token);

  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  const createTicketSchema = Yup.object().shape({
    Title: Yup.string()
      .min(4, strings.forms.error.min)
      .required(strings.forms.error.req),
    Content: Yup.string()
      .min(10, strings.forms.error.min10)
      .required(strings.forms.error.req),
  });

  const HandleLoading = () => {
    setLoading((prevValue) => !prevValue);
  };

  const HandleCreateTicket = (values) => {
    HandleLoading();
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .put(Ticket, values, config)
      .then((res) => {
        dispatch({ type: "USER_TICKET", userTicket: res.data });
        HandleLoading();
        history.push("/list");
      })
      .catch((err) => {
        if (err.response.data.message === "You already have a pending ticket") {
          dispatch({
            type: "SET_MSG",
            message: strings.forms.error.alrdytkt,
            severity: "error",
          });
          dispatch({ type: "SWITCH_ON" });
          HandleLoading();
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      Title: "",
      Content: "",
    },
    validationSchema: createTicketSchema,
    onSubmit: HandleCreateTicket,
  });

  const titleOptions = [
    "Mi dispositivo no se conecta a internet",
    "Mi dispositivo no puede utilizar las aplicaciones",
    "Mi dispositivo no responde al input táctil",
    "Mi dispositivo no enciende",
    "Mi dispositivo funciona, pero una aplicación esta fallando",
    "Otro problema (Detallar)",
  ];

  return (
    <StyDiv>
      <NavBar />
      <BodyCont>
        <TicketCont style={props}>
          <LogoImg src={Logo} alt="Logo" />
          <Typography style={{ marginBottom: "2rem" }} variant="h5">
            {strings.forms.msg.createtkt}
          </Typography>
          <FormControl fullWidth>
            <InputLabel>{strings.forms.inputs.ttle}</InputLabel>
            <Select
              margin="normal"
              fullWidth
              variant="outlined"
              label={strings.forms.inputs.ttle}
              name="Title"
              value={formik.values.Title}
              onChange={formik.handleChange}
              error={formik.touched.Title && Boolean(formik.errors.Title)}
              helperText={formik.touched.Title && formik.errors.Title}
            >
              {titleOptions.map((option) => {
                return <MenuItem value={option}> {option} </MenuItem>;
              })}
            </Select>
          </FormControl>

          <TextField
            margin="normal"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label={strings.forms.inputs.cntnt}
            name="Content"
            value={formik.values.Content}
            onChange={formik.handleChange}
            error={formik.touched.Content && Boolean(formik.errors.Content)}
            helperText={formik.touched.Content && formik.errors.Content}
          />
          <LoadingButton
            style={{ textTransform: "none", marginTop: "1rem" }}
            onClick={formik.handleSubmit}
            loading={loading}
            loadingIndicator={strings.btn.load}
            fullWidth
            color="success"
            variant="contained"
          >
            {strings.btn.create}
          </LoadingButton>
        </TicketCont>
      </BodyCont>
    </StyDiv>
  );
};

const StyDiv = styled.div`
  width: 100vw;
  height: 100vh;
`;
const TicketCont = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 40%;
  padding: 2rem;
  min-width: 350px;
`;
const LogoImg = styled.img`
  width: 90%;
  max-width: 300px;
  height: auto;
  margin-bottom: 1rem;
`;
const BodyCont = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default CreateTicket;
