const initState = {
  adminTickets: null,
  userTicket: null,
};

const ticketReducer = (state = initState, action) => {
  switch (action.type) {
    case "USER_TICKET":
      return {
        ...state,
        userTicket: action.userTicket,
      };
    case "ADMIN_TICKETS":
      return {
        ...state,
        adminTickets: action.adminTickets,
      };
    default:
      return { ...state };
  }
};

export default ticketReducer;
